<template>
  <div class="page inPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">ActionSheet</div>
    </div>
    <div class="pageBody">
      <lee-action-sheet v-model="show1" :actions="actions" round/>
      <lee-action-sheet v-model="show2" :actions="actions" cancel-text="取消" close-on-click-action round/>
      <lee-action-sheet v-model="show3" :actions="actions1" cancel-text="取消" description="人生定律"
  close-on-click-action round/>
      <lee-action-sheet v-model="show4" :actions="actions" cancel-text="取消" close-on-click-action
      @onCancel="onCancel" @onOpen="onOpen" @onClose="onClose" @onSelect="onSelect" round/>
      <lee-action-sheet v-model="show5" round title="标题">
        <div class="action-sheet-content">内容</div>
      </lee-action-sheet>
      <lee-action-sheet v-model="show6" :actions="actions2" cancel-text="取消" title="标题"  close-on-click-action round/>
      <lee-cell-group title="基础用法" inset>
        <lee-cell title="基础用法" is-link @click="show1 = true"></lee-cell>
        <lee-cell title="展示取消按钮" is-link @click="show2 = true"></lee-cell>
        <lee-cell title="展示描述信息" is-link @click="show3 = true"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="选项状态" inset>
        <lee-cell title="选项状态" is-link @click="show6 = true"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="事件监听" inset>
        <lee-cell title="监听事件" is-link @click="show4 = true"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="自定义面板" inset>
        <lee-cell title="自定义面板" is-link @click="show5 = true"></lee-cell>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {ref} from 'vue'
import {showToast} from '@/leeplus';
import {useRouter} from 'vue-router'
const router = useRouter()
const back = () => {
    router.push('/')
}
const show1=ref(false)
const show2=ref(false)
const show3=ref(false)
const show4=ref(false)
const show5=ref(false)
const show6=ref(false)
const actions = [
      { name: '选项一' },
      { name: '选项二' },
      { name: '选项三' },
    ];
const actions1 = [
      { name: '错误定律',subname:'当你觉得别人都不对时，那就要看看是不是自己的错了。' },
      { name: '效果定律',subname:'在伤口上落泪与在伤口上撒盐的效果是一样的。' },
      { name: '嫉妒定律',subname:'人们嫉妒的往往不是陌生人的飞黄腾达，而是身边的人加官晋爵。' },
    ];
const actions2 = [
      { name: '着色选项', color: '#ee0a24' },
      { name: '禁用选项', disabled: true },
      { name: '加载选项', loading: true },
    ];
const onCancel=()=>{
    showToast('取消')
}
const onClose=()=>{
    showToast('关闭')
}
const onOpen=()=>{
    showToast('打开')
}
const onSelect=(item,xh)=>{
    showToast('选中第'+xh+'项：'+item.name)
}

</script>
<style>
.action-sheet-content{
  padding: 16px 16px;
  min-height: 20vh;
}

</style>
